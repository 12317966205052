<template>
    <div class="order">
        <div class="zk-main-title">
            <h2>预付订单查询</h2>
        </div>

        <el-card class="query-form">
            <el-form :inline="true" :model="queryForm" ref="queryForm">
                <el-form-item label="订单来源" prop="bizType">
                    <el-select v-model="queryForm.bizType" filterable>
                        <el-option
                            label="全部（猫享与考拉）"
                            :value="-1"
                        />
                        <el-option
                            label="猫享自营"
                            :value="1"
                        />
                        <el-option
                            label="考拉海购"
                            :value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="下单时间" prop="queryTime">
                    <el-date-picker
                        v-model="queryForm.queryTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '00:00:00']"
                        value-format="timestamp"
                        @change="handleRangeChange"
                    />
                </el-form-item>
                <el-form-item label="推广位" prop="positionId">
                    <el-select v-model="queryForm.positionId" filterable>
                        <el-option
                            v-for="item in positionOptions"
                            :key="item.positionId"
                            :label="item.name"
                            :value="item.positionId"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onQuery">查询</el-button>
                    <el-button @click="resetForm">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="query-table" v-loading="loading">
            <div class="result-table" v-if="tableData">
                <div class="result-table-header clearfix">
                    <span class="result-table-title">推广订单</span>
                    <div class="result-table-export">
                        <el-button type="primary" @click="exportEmail">导出</el-button>
                    </div>
                </div>
                <div class="alert-tip">
                    <el-alert title="您推广的预付商品用户提交订单后即展示到下表，付尾款前此类订单不会出现在订单明细，也不会产生实际收益，付尾款后实际收益以付尾款时实付净额为准。"
                              type="warning"
                              show-icon
                    />
                </div>

                <div class="result-table-content clearfix">
                    <el-table :data="tableData.data">
                        <el-table-column
                            prop="orderTime"
                            :formatter="formatOrderTime"
                            label="下单时间"
                            min-width="3"
                        />
                        <el-table-column label="订单来源" min-width="2">
                            <template slot-scope="scope">
                                <p>{{ scope.row.bizType | formatBizType }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsName" label="商品名称" min-width="2" />
                        <el-table-column prop="goodsId" label="商品id" min-width="2" />
                        <el-table-column prop="categoryName" label="类目" min-width="2" />
                        <el-table-column prop="positionName" label="推广位" min-width="2" />
                        <el-table-column prop="buyCount" label="购买数量" min-width="2" />
                        <el-table-column prop="payAmount" label="商品价格" min-width="2" />
                        <el-table-column label="佣金比例" min-width="2">
                            <template slot-scope="scope">
                                <p>{{ scope.row.commissionRate | formatCommissionRate }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="commissionAmount" min-width="2">
                            <template slot="header">
                                <div style="padding-left: 0;">
                                    <span>预估佣金 </span>
                                    <reward-tooltip>
                                        <p>用户付尾款时可能使用红包、优惠券等优惠方式抵扣部分金额，实际佣金以付尾款时的实付净额计算为准</p>
                                    </reward-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pagination" v-if="tableData.data && tableData.data.length > 0">
                        <pagination
                            :total="tableData.totalCount"
                            :page-sizes="pageSizes"
                            :page-size="queryForm.pageSize"
                            :current-page="queryForm.pageIndex"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                        />
                    </div>
                </div>
            </div>
        </el-card>

        <export-email-dialog :visible.sync="emailDialogVisible" @email-value="getExportEmail" />
    </div>
</template>

<script>
import moment from 'moment';
import ExportEmailDialog from '../../components/settle/export-email';
import pagination from '../../components/common/pagination.vue';
import formatPrice from '../../utils/format-price';
import Tooltip from '../../components/settle/tooltip';
import getDayEnd from '../../utils/get-day-end';
import safeNumber from '../../utils/safe-number';

export default {
    components: {
        pagination,
        'reward-tooltip': Tooltip,
        'export-email-dialog': ExportEmailDialog
    },
    data() {
        const todayStartTime = moment(moment().format('YYYY-MM-DD')).valueOf();
        const dayMs = 24 * 60 * 60 * 1000;
        const positionOptions = [
            {
                positionId: '-1',
                name: '全部'
            }
        ];
        const pageSizes = [20, 50, 100, 200, 500];
        const timeLimit = {
            min: null,
            max: todayStartTime
        };
        return {
            pickerOptions: {
                disabledDate: (date) => {
                    const curDate = date.getTime();
                    const { min: minDate, max: maxDate } = timeLimit;
                    let optional = true;
                    if (minDate) {
                        optional = optional && curDate >= minDate;
                    }
                    if (maxDate) {
                        optional = optional && curDate <= maxDate;
                    }
                    return !optional;
                },

                onPick: ({ minDate, maxDate }) => {
                    if (minDate && !maxDate) {
                        timeLimit.min = moment(minDate)
                            .subtract(1, 'months')
                            .add(1, 'days')
                            .valueOf();

                        const timeLimitMax = moment(minDate)
                            .add(1, 'months')
                            .subtract(1, 'days')
                            .valueOf();
                        timeLimit.max =
                            timeLimitMax > todayStartTime ? todayStartTime : timeLimitMax;
                    } else {
                        timeLimit.min = null;
                        timeLimit.max = todayStartTime;
                    }
                }
            },
            queryForm: {
                queryTime: [todayStartTime - dayMs * 6, todayStartTime],
                positionId: positionOptions[0].positionId,
                pageIndex: 1,
                pageSize: pageSizes[0],
                bizType: -1
            },
            positionOptions,
            pageSizes,
            tableData: {
                totalCount: 0,
                data: []
            },
            emailDialogVisible: false,
            loading: false
        };
    },
    filters: {
        formatCommissionRate(rate) {
            return `${safeNumber.times(rate, 100)}%`;
        }
    },
    mounted() {
        this.getCorpZhuankePromotionPosition();
    },
    methods: {
        handleRangeChange(v) {
            const s = +new Date(v[0]);
            const e = getDayEnd(+new Date(v[1]));
            if(e - s > 1000 * 60 * 60 * 24 * 30) {
                this.$message.error('您选择的时间区间过长, 最多选择一个月的数据');
            }
        },
        async getCorpZhuankePromotionPosition() {
            const data = await this.$request({
                url: '/api/promotion/getCorpZhuankePromotionPosition'
            });

            this.positionOptions = [...this.positionOptions, ...(data || [])];
            this.initQueryData();
            this.onQuery();
        },
        initQueryData() {
            let { startTime, endTime, positionId } = this.$route.query;
            startTime = parseInt(startTime, 10);
            endTime = parseInt(endTime, 10);
            if (startTime && endTime) {
                this.queryForm.queryTime = [
                    moment(moment(startTime).format('YYYY-MM-DD')).valueOf(),
                    moment(moment(endTime).format('YYYY-MM-DD')).valueOf()
                ];
            }
            if (positionId) {
                this.queryForm.positionId = positionId;
            }
        },
        onQuery() {
            this.resetTable();
            this.queryTable();
        },
        resetTable() {
            this.queryForm.pageIndex = 1;
            this.queryForm.pageSize = this.pageSizes[0];
        },
        async queryTable() {
            let {
                queryTime: [startTime, endTime],
                positionId,
                pageIndex,
                pageSize,
                bizType
            } = this.queryForm;
            endTime += 24 * 60 * 60 * 1000;

            this.loading = true;
            const params = {
                startTime,
                endTime,
                positionId,
                pageIndex,
                pageSize
            };

            if(bizType !== -1) {
                params.bizType = bizType;
            }

            const data = await this.$request({
                url: '/api/report/order/getDepositOrderListByParam',
                method: 'post',
                data: params
            });

            this.loading = false;
            const { maxCount, orderList } = data || {};

            this.tableData = { totalCount: maxCount, data: orderList };
            this.tableData.data = (this.tableData.data || []).map((item) => {
                let positionName = '';
                if(item.positionId === '-1') {
                    positionName = '';
                } else {
                    this.positionOptions.forEach((option) => {
                        if(item.positionId === option.positionId) {
                            positionName = option.name;
                        }
                    });
                }
                item.positionName = positionName;
                return item;
            });
        },
        resetForm() {
            this.$refs.queryForm.resetFields();
        },
        exportEmail() {
            this.emailDialogVisible = true;
        },
        getExportEmail(email) {
            let {
                queryTime: [startTime, endTime],
                positionId
            } = this.queryForm;
            endTime += 24 * 60 * 60 * 1000;

            this.$request({
                url: '/api/report/order/exportDepositOrder',
                method: 'post',
                origin: true,
                data: {
                    startTime,
                    endTime,
                    positionId,
                    email
                }
            })
                .then((data) => {
                    if (data.code === 200) {
                        this.showExportOftenAlert();
                    } else {
                        this.showExportErrorAlert(data.desc || '');
                    }
                })
                .catch(() => {
                    this.showExportErrorAlert();
                });
        },
        showExportErrorAlert(errText) {
            const err = errText ? errText : '导出失败';
            this.$alert(err, '提示', {
                confirmButtonText: '确定'
            });
        },

        showExportOftenAlert() {
            this.$alert(
                '您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您',
                '提示',
                {
                    confirmButtonText: '确定'
                }
            );
        },
        handleCurrentChange(newPageIndex) {
            this.queryForm.pageIndex = newPageIndex;
            this.queryTable();
        },
        handleSizeChange(newPageSize) {
            this.queryForm.pageIndex = 1;
            this.queryForm.pageSize = newPageSize;
            this.queryTable();
        },
        formatOrderTime(row, column, cellValue) {
            return moment(parseInt(cellValue, 10) || Date.now()).format('YYYY.MM.DD HH:mm:ss');
        },
        formatPrice(row, column, cellValue) {
            return formatPrice(cellValue);
        },
        formatAmount(row, column, cellValue) {
            return `¥${formatPrice(cellValue)}`;
        },
        formatRate(row, column, cellValue) {
            return `${parseFloat(cellValue) * 100}%`;
        }
    }
};
</script>

<style lang="scss" scoped>
.order {
    .query-form {
    }
    .query-table {
        margin-top: 20px;
        .result-table {
            .result-table-header {
                .result-table-title {
                    font-size: 18px;
                    line-height: 40px;
                    font-weight: bold;
                }
                .result-table-export {
                    float: right;
                }
            }
            .result-table-content {
                .pagination {
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: '';
        }
        .clearfix:after {
            clear: both;
        }
    }
}
</style>

<style lang="scss">
.order {
    .alert-tip{
        margin: 20px 0;
    }
    .query-form {
        .el-range-separator {
            box-sizing: content-box;
        }
        .el-form-item {
            margin-right: 20px;
        }
        .el-card__body {
            padding-bottom: 0;
        }
    }
}
</style>
